import React from 'react'
import IMG1 from '../../assets/thumbnail1.png'
import IMG2 from '../../assets/placeholder1.png'
import { HiCommandLine} from 'react-icons/hi2'
const Project = () => {


  return (
    <section>
      <div className='header'>
        <h2>My Recent Work</h2>
      </div>
      <div className='portfolio_container'>
        <article className='portfolio_item'>
          <div className='portfolio_item-image'>
            <img src={IMG1} width='300px' alt="SVG Parser & Editor" />
            <h3>SVG Editor</h3>
            <div className='portfolio_item_text'>
              <p>A web-based application that allows users to upload and view SVG files using a SVG parsing library to extract information from the file. This information is displayed on an interactive web page, where users can edit and change the attributes of the file, such as the fill color, stroke width, and position. Additionally, the program would allow users to add new shapes to the SVG file, such as rectangles, circles, and polygons. The user can then download their modified SVG's</p>
            </div>
            <div className='portfolio_item-cta'>
              <a href="https://gitfront.io/r/user-9964840/MqCvUBtFhreF/SVGEditor/" target={'_blank'} rel='noreferrer'>{<HiCommandLine size={'5rem'} />}</a>
              {/* <a href="https://github.com/" target={'_blank'} rel='noreferrer'>{<HiComputerDesktop size={'5rem'} />}</a> */}
            </div>
          </div>
        </article>
        <article className='portfolio_item'>
          <div className='portfolio_item-image'>
            <img src={IMG2} width='300px' height='227.45px' alt="Hangman" />
            <h3>Hangman</h3>
            <div className='portfolio_item_text'>
              <p>This is a simple hangman program that allows the user the option to play against another person, AI or Autoplay, it also allows the user to choose from a list of genres for a word to be picked from.</p>
            </div>
            <div className='portfolio_item-cta'>
              <a href="https://github.com/BrWri4227/HangMan" target={'_blank'} rel='noreferrer'>{<HiCommandLine size={'5rem'} />}</a>
              {/* <a href="https://github.com/" target={'_blank'} rel='noreferrer'>{<HiComputerDesktop size={'5rem'} />}</a> */}
            </div>
          </div>
        </article>
      </div>
    </section>
  );
}

export default Project

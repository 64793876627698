import React from 'react'
import { Link, NavLink} from "react-router-dom";

const Navbar = () => {
  return (

    <nav className="navbar">
    <div className='name'>
      <Link to='/'>Brycen Wright</Link>
      </div>
      
          <ul>
            <li>
              {/* <Link to="/">Home</Link> */}
              {/* <a href="/">Home</a> */}
              {/* <CustomLink to="/">Home</CustomLink> */}
              <NavLink to="/">Home</NavLink>
            </li>
            <li>
              {/* <Link to="/Projects">Projects</Link> */}
              {/* <a href="/Projects">Projects</a> */}
              {/* <CustomLink to="/Projects">Projects</CustomLink> */}
              <NavLink to="/Projects">Projects</NavLink>

            </li>
            <li>
              {/* <Link to="/Contact">Contact</Link> */}
              {/* <a href="/Contact">Contact</a> */}
              {/* <CustomLink to="/Contact">Contact</CustomLink> */}
              <NavLink to="/Contact">Contact</NavLink>

            </li>
          </ul>
    </nav>
  )
}

// function CustomLink({to,children,...props}){
// const resolvedPath = useResolvedPath(to)
// const isActive = useMatch({path: resolvedPath.pathname, end: true})

  
//   return (
//     <li className={isActive === to ? "active" : ""}>
//       <Link to={to} {...props}>{children}</Link>
//     </li>
//   )
// }
export default Navbar
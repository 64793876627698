import React from 'react'
import { GrLinkedin, GrInstagram, GrGithub, GrMail, GrTextAlignFull } from 'react-icons/gr'
import PDF from '../../assets/Brycen Wright Resume.pdf'

const Home = () => {
  return (
    
    <div>
      
      <div className='header_container'>
        <h5>Hi, I'm</h5>
        <h5>Brycen Wright</h5>
      </div>
      <div className='contact_links'>
        <div>
          <a rel='noreferrer' target="_blank" href="https://www.linkedin.com/in/brycen-wright-5b7625223/"><GrLinkedin className='contact_icons' /></a>
          <a target="_blank" rel='noreferrer' href="https://instagram.com/bry.shade/"><GrInstagram className='contact_icons' /></a>
          <a target="_blank" rel='noreferrer' href="https://github.com/BrWri4227"><GrGithub className='contact_icons' /></a>
          <a target="_blank" rel='noreferrer' href="mailto:BrWri@outlook.com?subject=Website Contact&body=TestBody%0D%0A"><GrMail className='contact_icons' /></a>
          <a target="_blank" rel='noreferrer' href={PDF}><GrTextAlignFull className='contact_icons' /></a>
        </div>
      </div>
      <div className='aboutMe'>
        <h2>Hi there! I am a third-year computer science student at the University of Guelph. I am passionate about programming and technology, and enjoy spending my free time tinkering with new software and hardware. In addition to my interests in computer science, I also enjoy playing games and hanging out with friends. I am excited to explore new opportunities and continue learning in the field of computer science.</h2>
        
      </div>
      <h2 style={{textAlign: 'left', padding: '0 10rem'}}>I have experience with: </h2>
      <div className='skillList'>
       
        <ul>
          <li>C</li>
          <li>Java</li>
          <li>Python</li>
          <li>VBA</li>
          <li>VB 2010</li>
          <li>HTML/CSS</li>
          <li>JavaScript</li>
          <li>React</li>
          <li>Node.js</li>
          <li>SQL</li>
        </ul>
      </div>
    </div>
  )
}

export default Home
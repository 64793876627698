import React from 'react';
import './App.css';
import { Contact, Home, Navbar, Project } from './components'
import {Footer } from './containers';

import { Route, Routes} from "react-router-dom"

const App = () => {
  // let Component
  // switch(window.location.pathname){
  //   case "/":
  //     Component = Home
  //     break
  //   case "/Projects":
  //     Component = Project
  //     break
  //   case "/Contact":
  //     Component = Contact
  //     break
  // }

  return (
    <div className="App">
      <div className="DaBar">
        <Navbar />
        {/* <Header /> */}
      </div>

      {/* <Component/> */}
      <div className='page-container'>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        {/* <Route path="/Projects" element={<WorkCard/>}></Route> */}
        <Route path="/Projects" element={<Project/>}></Route>

        <Route path="/Contact" element={<Contact />}></Route>
      </Routes>
      <Footer />
      </div>
      {/* {comp} */}
      {/* <Connect /> */}
      {/* <Experience /> */}
      
      {/* <Intro /> */}
    </div>

  );
}

export default App;

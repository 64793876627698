import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import "./components/navbar/navbar.css"
import "./components/Home/home.css"
import "./containers/footer/footer.css"
import "./components/Projects/project.css"
import "./components/WorkCard/workcard.css"
import "./components/Contact Me/contact.css"
import { BrowserRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>

);
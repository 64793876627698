import React, { useState } from 'react';
import {GrMap, GrPhone, GrMail} from 'react-icons/gr'

// function ContactForm() {
//   const [formData, setFormData] = useState({ name: '', email: '', message: '' });

//   const handleSubmit = event => {
//     event.preventDefault();
//     console.log(formData);
//     // Do something with the form data here, like send it to an API
//     setFormData({ name: '', email: '', message: '' });
//   };
//   const handleSubmit = event => {
//     event.preventDefault();
//     console.log(formData);
//     setFormData({ name: '', email: '', message: '' });
//   };

//   const handleChange = event => {
//     const { name, value } = event.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <label>
//         Name:
//         <input type="text" name="name" value={formData.name} onChange={handleChange} />
//       </label>
//       <br />
//       <label>
//         Email:
//         <input type="email" name="email" value={formData.email} onChange={handleChange} />
//       </label>
//       <br />
//       <label>
//         Message:
//         <textarea name="message" value={formData.message} onChange={handleChange} />
//       </label>
//       <br />
//       <button type="submit">Send</button>
//     </form>
//   );
// }

// export default ContactForm;

function ContactForm() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });

  const handleSubmit = async event => {
    event.preventDefault();
    if (formData.name === "" || formData.email === "" ) {
      alert("Form is empty, please fill in all fields");
      return;
    }
    const { name, email, message } = formData;
    // console.log(JSON.stringify(content))
    const msg = { "content": "Hi, someone has just tried to contact you! Here is their information:\nName: " + name + "\nEmail: " + email + "\nMessage: " + message }
    try {
      const response = await fetch("https://discord.com/api/webhooks/1062606366033858690/g-zZy7dgR7oawrpCHVO5gxyfEA-csoioRBoIFsJByQq9DD_BY7xaPVeijrTNBsv0-pZ7", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        // body: JSON.stringify(content)
        body: JSON.stringify(msg)

      });
      if (response.ok) {
        console.log("Successfully sent message to Discord");
        alert("Message Sent Successfully, Thanks for using the form!")
      } else {
        console.error("Error sending message to Discord");
      }
    } catch (e) {
      console.error(e);
    }

    setFormData({ name: '', email: '', message: '' });
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      <section className='contact'>
        <div className='content'>
          <h1>Contact Me</h1>
          <p>Please feel free to contact me by any of the methods below!</p>
          <div className='box'>
                <GrMap/>
                <div className='text'>
                  <h3>Address </h3>
                  <p>50 Stone Rd E, Guelph, ON N1G 2W1</p>
                </div>
              </div>
          <div className='container'>
              

            <div className='box'>
                <GrPhone/>
              <div className='text'>
                <h3>Phone Number </h3>
                <p>226-337-3700</p>
              </div>
            </div>

          <div className='box'>
            <GrMail/>
            <div className='text'>
              <h3>Email</h3>
              <p>BrWri@outlook.com</p>
            </div>
          </div>
        </div>
        </div>



      </section>
      <h1>Fill out this Form</h1>
      <form onSubmit={handleSubmit}>
        <label>
          Name*:
          <input type="text" name="name" value={formData.name} onChange={handleChange} />
        </label>
        <br />
        <label>
          Email*:
          <input type="email" name="email" value={formData.email} onChange={handleChange} />
        </label>
        <br />
        <label>
          Message*:
          <textarea name="message" value={formData.message} onChange={handleChange} />
        </label>
        <br />
        <button className='submitBtn' type="submit">Send</button>
      </form>
    </div>
  );
}

export default ContactForm;
